import { ShippingAddress } from '@/components/elements/checkout/v2/CheckoutForm/CheckoutForm'
import { Basket, CheckoutCountry, CurrencyCode, DiscountTypeV2, Product } from './types'

export const formatCurrency = (
  currency?: CurrencyCode,
  amount?: number,
  stripTrailingZeroes: boolean = false,
): string => {
  if (amount === undefined) {
    return ''
  }

  const locale = (typeof navigator !== 'undefined' && navigator.language) || 'en-us'

  const minimumFractionDigits = stripTrailingZeroes && amount % 100 === 0 ? 0 : 2

  if (currency === undefined) {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits,
    }).format(amount / 100)
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency.toUpperCase(),
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits,
  }).format(amount / 100)
}

export type OrderConfirmationData = {
  orderNumber: string
  orderEmail: string
  orderBasket: Basket
  firstName: string
  lastName: string
  shippingAddress: ShippingAddress
  country: CheckoutCountry
  orderSource: string
  plansUrl?: string
  checkoutUrl: string
  marketingOptIn: boolean
}
export const storeOrderConfirmationData = (orderConfirmationData: OrderConfirmationData) => {
  typeof window !== 'undefined' &&
    window.sessionStorage.setItem('orderConfirmationData', JSON.stringify(orderConfirmationData))
}

export const retrieveOrderConfirmationData = (): OrderConfirmationData => {
  return typeof window !== 'undefined' && JSON.parse(window.sessionStorage.getItem('orderConfirmationData') ?? '')
}

export const formatDiscount = (currency?: CurrencyCode, type?: DiscountTypeV2, amount?: number): string => {
  if (amount && type === 'percent') {
    return `${amount}%`
  }

  if (currency && amount && type === 'amount') {
    return `${formatCurrency(currency, amount)}`
  }

  return ''
}

export const getCountryCurrency = (country: CheckoutCountry): CurrencyCode => {
  switch (country) {
    case 'US':
      return 'usd'
    case 'GB':
      return 'gbp'
  }
}
