import { DSText, DSSpacer } from '@zoe/ds-web'
import { processTypographyMarkdown } from '@/components/blocks/DesignSystem/Typography'
import styled from 'styled-components'
import { BREAKPOINTS } from '../breakpoints'

export const BANNER_HEIGHT_DESKTOP = 44
export const BANNER_HEIGHT_MOBILE = 64

const StyledBanner = styled.div`
  position: fixed;
  background-color: var(--colour-surface-aubergine-bold);
  top: 0;
  right: 0;
  width: 100vw;
  max-width: 100%;
  z-index: 4;
  min-height: ${BANNER_HEIGHT_DESKTOP}px;
  margin-bottom: var(--grid-12);
  @media screen and (max-width: ${BREAKPOINTS.s}) {
    height: ${BANNER_HEIGHT_MOBILE}px;
  }
`

const StyledBannerTextContainer = styled.div`
  max-width: 90%;
  margin: 0 auto;
`

export const Banner = ({ text }) => {
  return (
    <StyledBanner>
      <DSSpacer size={12} direction="vertical" />
      <StyledBannerTextContainer>
        <DSText variant="fluid-paragraph-200" weight="lotaLight" colour="primary-inverse" align="center">
          {processTypographyMarkdown({
            content: text,
            variant: 'fluid-paragraph-200',
            weight: 'lotaLight',
            colour: 'primary-inverse',
          })}
        </DSText>
      </StyledBannerTextContainer>
      <DSSpacer size={12} direction="vertical" />
    </StyledBanner>
  )
}
